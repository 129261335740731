.gym-opening-times{
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__headline{
    font-weight: bold;
    font-size: 30px;
  }

  &__text{
    font-size: 20px;
  }

  &__content{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 12px;

    &__item{

    }
  }
}
