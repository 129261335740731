.file-input {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    background-color: var(--secondary-background-color);
    border: 1px solid var(--thin-border-color);
    padding: 24px;
    border-radius: 6px;
    width: calc(100% - 48px);
    gap: 6px;
    max-width: 400px;

    &__text {
        display: flex;
        justify-content: center;

        span {
            color: var(--primary-color);
            cursor: pointer;
        }
    }
}
