.card {
  position: relative;
  margin-top: 20px;

  flex-shrink: 0;

  transition: opacity 0.3s;

  &__badge {
    position: absolute;
    border-color: var(--thin-border-color);
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;

    background-color: var(--secondary-background-color);

    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 6px 12px;
  }

  &__wrapper {
    overflow: hidden;
    height: 100%;

    background-color: var(--secondary-background-color);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 12px;

  }

  &__button {
    margin: 12px;
  }
}
