.footer{
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);

  min-height: 32px;
  padding: 12px 24px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  gap: 12px;

  &__item{
    cursor: pointer;

    &:hover{
      opacity: .8;
    }
  }
}
