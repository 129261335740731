.search {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  border-radius: 100px;
  background-color: var(--primary-background-color);

  &__icon {
    background-color: var(--secondary-color);
    color: #FFF;
    border-radius: 100px;
    height: 35px;
    display: flex;
    aspect-ratio: 1;
    margin-right: 6px;
    justify-content: center;
    align-items: center;
  }

  input {
    margin-left: 14px;
    border: none;
    background-color: transparent;
    color: var(--text-color);
    padding: 14px 0;
    width: 100%;
  }

  input:focus-visible {
    outline: none;
  }
}
