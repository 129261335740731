.header{
  height: 64px;

  display: flex;
  align-items: center;
  background-color: var(--secondary-background-color);

  padding: 0 24px;
  border-bottom: 1px solid var(--thin-border-color);
}
