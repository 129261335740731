.card-slider {
  display: flex;
  gap: 16px;
  padding: 0 12px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
    background-color: transparent;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
    background-clip: padding-box;
    border: solid 3px transparent;
  }
}
