.booking {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 200px;
  max-width: 400px;
  padding: 12px;
  margin: 12px auto;

  &__headline {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__text {
      margin: 0 14px;
    }
  }
}
