.home {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
  padding: 0 12px;

  &__headline {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
  }
}
