.scroll-container{
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;

  > div{
    margin-right: 2px !important;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
    background-color: transparent;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
    background-clip: padding-box;
    border: solid 3px transparent;
  }
}
