.splash-screen {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 101;
    background-color: var(--primary-color);

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 20%;
    }
}
