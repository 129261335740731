.logo{
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  user-select: none;

  img{
    height: 40px;
  }
}
