.button {
  background-color: var(--primary-color);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  color: #fff;
  text-align: center;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
}
