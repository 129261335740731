.gym-contact{
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__headline{
    font-weight: bold;
    font-size: 30px;
  }

  &__text{
    font-size: 20px;
  }

}
