.input{
  display: flex;
  gap: 6px;
  border-radius: 100px;
  border: 1px solid var(--thin-border-color);

  align-items: center;
  padding: 12px;
  background-color: var(--secondary-background-color);

  input{
    border: none;
    background-color: transparent;
    width: 100%;
  }

  input:focus-visible {
    outline: none;
  }
}
