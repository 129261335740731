.no-content {
  margin: 1em auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  a{
    color: var(--primary-color);
    cursor: pointer;
  }

  &__animation-container {
    position: relative;
    display: block;
    text-align: center;
    height: 100px;
    width: 100%;
    border-bottom: solid 2px #333;
    overflow: hidden;

    &__bounce {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 40px;
      height: 40px;
      margin-left: -20px;
      background-size: contain;
      animation: bounceAnim 1s cubic-bezier(0.63, 0.09, 0.75, 0.46) infinite alternate,
      spinAnim 3s linear infinite;
    }

    &__pebble1,
    &__pebble2,
    &__pebble3 {
      position: absolute;
      background-color: #333;
      border-radius: 50% 50% 0 0;
    }

    &__pebble1 {
      top: 95%;
      width: 10px;
      height: 10px;
      animation: pebblesAnim 1s linear infinite;
    }

    &__pebble2 {
      top: 97%;
      width: 5px;
      height: 5px;
      animation: pebblesAnim 2s linear infinite;
    }

    &__pebble3 {
      top: 98%;
      width: 3px;
      height: 3px;
      animation: pebblesAnim 3s linear infinite;
    }
  }
}

@keyframes bounceAnim {
  0%, 10% {
    bottom: 50%;
  }

  100% {
    bottom: 0%;
  }
}

@keyframes spinAnim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pebblesAnim {
  0% {
    right: -20%;
  }
  100% {
    right: 110%;
  }
}
