.gym-benefits {
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin: 0 12px;

  &__headline{
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
  }

  &__image {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    height: fit-content;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
